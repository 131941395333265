import {
  FormEvent,
  memo,
  useState,
  useImperativeHandle,
  forwardRef,
  useRef,
  Dispatch,
  SetStateAction,
  useEffect,
} from "react";
import { FormattedMessage } from "react-intl";

import classNames from "classnames";
import styles from "./chatFooter.module.scss";
import { useEffectOnce } from "react-use";

import { StopGenerateButton } from "./components/StopGenerateButton";
import { ScrollToBottomButton } from "./components/ScrollToBottomButton";
import { CountDD } from "./components/CountDD";
import { SizeDD } from "./components/SizeDD";
import { SearchField } from "../searchField";
import { PromptIcon } from "../icons";
import { PromptLibrary } from "./components/PromptLibrary";
import { ChatRoute, IUploadFile } from "pages/ChatPage/ChatPage";

import { ImageCountOptions } from "utils/constants";
import { useToggleSidebar } from "hooks/services/ToggleSidebarProvider";
import useRouter from "hooks/useRouter";
import { Tooltip } from "components/base";
import { useSelector } from "redux/hooks";
import {
  ChatType,
  IChat,
  getAllPropts,
  setImageAttribute,
  getSavePrompts,
  EChatType,
  startNewChat,
  IChatModel,
} from "redux/actions";
import { ShareFooter } from "./components/ShareFooter";
import { ImageGeneration } from "./components/Icons";
import { IFileErrorState } from "pages/ChatPage/pages/newChat/NewChat";
import { RoutePaths } from "pages/routePaths";
import ErrorModalSection from "./components/ErrorModalSection";
import { calculateCredit } from "utils/functions";
import { CreditDeduction } from "./components/CreditDeduction";

interface IProps {
  onSubmit?: ({
    event,
    message,
  }: {
    event: FormEvent<EventTarget | HTMLFormElement>;
    message: string;
  }) => void;
  getMessagesLoading?: boolean;
  onEnter?: ({
    event,
    message,
  }: {
    event: React.KeyboardEvent<HTMLTextAreaElement>;
    message: string;
  }) => void;
  isAnswerComplete?: boolean;
  isGenerating?: boolean;
  onStopGeneratingResponse?: () => void;
  isFileUploading: boolean;
  showScrollToBottom?: boolean;
  share?: boolean;
  scrollToBottom?: ({ behavior }: ScrollOptions) => void;
  selectedMessages?: any;
  toggleShareChat?: () => void;
  handleSelectAllChats?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  selectedChatId?: any;
  loadingShareChat?: boolean;
  setSelectedMessages?: Dispatch<SetStateAction<any[]>>;
  setChatHistory?: Dispatch<SetStateAction<any>>;
  chatHistoryPagination?: IChat[];
  settings: {
    real_time_results: boolean;
    related_questions: boolean;
    send_message_with_enter: boolean;
  };
  updateChatModel?: (modalType: EChatType) => void;
  setSelectedFile: Dispatch<SetStateAction<File[] | null>>;
  selectedFile: File[] | null;
  setIsMainScreenOpen?: Dispatch<SetStateAction<boolean>>;
  setIsFileUploading?: Dispatch<SetStateAction<boolean>>;
  IGLoading?: boolean;
  onSendMessage: (
    question: string,
    chatModels?: IChatModel,
    regenerate?: boolean,
    images?: string[],
    filePath?: string[]
  ) => void;
  openHistory?: boolean;
  setOpenHistory?: Dispatch<SetStateAction<boolean>>;
  credit: number;
  setCredit: Dispatch<SetStateAction<number>>;
  uploadingFiles?: IUploadFile[];
  fileS3Link?: string[];
  setUploadingFiles?: Dispatch<SetStateAction<IUploadFile[]>>;
  setFileS3Link?: Dispatch<SetStateAction<string[]>>;
  isAllowUploadFile?: boolean;
  chatItem?: IChat;
}

export interface ChatFooterMethods {
  resetMessage: () => void;
  onTextareaFocus: () => void;
}

export const ChatFooter = memo(
  forwardRef<ChatFooterMethods, IProps>(
    (
      {
        onSubmit,
        getMessagesLoading,
        onEnter,
        isAnswerComplete,
        isGenerating,
        onStopGeneratingResponse,
        isFileUploading,
        showScrollToBottom,
        scrollToBottom,
        share,
        selectedMessages,
        toggleShareChat,
        handleSelectAllChats,
        selectedChatId,
        loadingShareChat,
        setSelectedMessages,
        settings,
        updateChatModel,
        chatHistoryPagination,
        setChatHistory,
        setSelectedFile,
        selectedFile,
        setIsMainScreenOpen,
        setIsFileUploading,
        IGLoading,
        onSendMessage,
        openHistory,
        setOpenHistory,
        credit,
        setCredit,
        uploadingFiles,
        fileS3Link,
        setUploadingFiles,
        setFileS3Link,
        isAllowUploadFile,
        chatItem,
      },
      ref
    ) => {
      const { includeRoute, push, pathname } = useRouter();
      const { isOpen } = useToggleSidebar();
      const { gptModel, theme, imageAttributes } = useSelector(
        (state) => state.authReducer
      );
      const chatModel = useSelector((state) => state.authReducer.gptModel);
      const { messages, newMessages } = useSelector(
        (state) => state.chatReducer
      );
      const creditPerQuery = pathname.includes("chat/new");

      const [showPromptModal, setShowPromptModal] = useState<boolean>(false);
      const textareaRef = useRef<HTMLTextAreaElement | null>(null);

      const [message, setMessage] = useState<string>("");
      const resetMessage = () => {
        setMessage("");
      };
      const getMessage = (e: React.ChangeEvent<HTMLTextAreaElement>) =>
        setMessage(e.target.value);

      const [isTextareaFocused, setIsTextareaFocused] =
        useState<boolean>(false);
      const [promptLoading, setPromptLoading] = useState<boolean>(false);
      const [userPromptLoading, setUserPromptLoading] =
        useState<boolean>(false);
      const [uploadURl, setUploadUrl] = useState<boolean>(false);
      const [url, setURL] = useState<string>("");
      const [creditsPerQuery, setCreditsPerQuery] = useState<boolean>(false);
      const [selectIFile, SetSelectIFile] = useState<boolean>(false);

      const isShareChat = window.location.pathname.includes("share-chat");
      const [errorModal, setErrorModal] = useState<IFileErrorState>({
        message: "",
        show: false,
      });

      useEffectOnce(() => {
        if (!isShareChat) {
          setPromptLoading(true);
          setUserPromptLoading(true);

          Promise.all([
            getSavePrompts().then(
              () => setUserPromptLoading(false),
              () => setUserPromptLoading(false)
            ),
            getAllPropts().then(
              () => setPromptLoading(false),
              () => setPromptLoading(false)
            ),
          ]).finally(() => {
            setPromptLoading(false);
            setUserPromptLoading(false);
          });
        }
      });

      useEffect(() => {
        if (newMessages[0]?.images && newMessages[0]?.images.length > 0)
          setCreditsPerQuery(true);
        else setCreditsPerQuery(false);
        if (selectedFile) {
          if (
            selectedFile &&
            selectedFile?.length > 0 &&
            selectedFile[0]?.type.startsWith("image/")
          )
            SetSelectIFile(true);
          else SetSelectIFile(false);
        }
      }, [newMessages, selectedFile]);

      useEffect(() => {
        if (!gptModel) return;
        if (isAnswerComplete === true) {
          const credit = calculateCredit(
            gptModel,
            settings,
            uploadingFiles,
            url,
            isAnswerComplete
          );
          setCredit(credit);
        }
      }, [
        message,
        uploadingFiles,
        newMessages,
        messages,
        settings,
        gptModel,
        url,
        isAnswerComplete,
        isFileUploading,
      ]);

      const onTextareaFocus = () => {
        if (textareaRef.current) textareaRef.current.focus();
      };

      useImperativeHandle(ref, () => ({
        resetMessage,
        onTextareaFocus,
      }));

      const isContentFound = (): boolean => {
        let isContent: boolean = false;
        if (
          includeRoute(ChatRoute.History) &&
          messages[messages.length - 1].content
        ) {
          isContent = true;
        }

        if (
          includeRoute(ChatRoute.New) &&
          newMessages[newMessages.length - 1].content
        ) {
          isContent = true;
        }

        return isContent;
      };

      const onOpenPromptLibrary = () => setShowPromptModal(true);

      const handleSubmit = (event: React.FormEvent) => {
        if (
          fileS3Link &&
          fileS3Link.length > 0 &&
          openHistory === false &&
          !isFileUploading
        ) {
          const s3Links: string[] = (uploadingFiles ?? [])
            .map((file) => file.S3Link)
            .filter((link): link is string => link !== undefined);
          if (uploadingFiles && uploadingFiles[0]?.fileType === "image")
            onSendMessage!(
              message ? message : "",
              chatModel,
              false,
              s3Links ?? []
            );
          else if (uploadingFiles && uploadingFiles[0]?.fileType === "document")
            onSendMessage!(
              message ? message : "",
              chatModel,
              false,
              [],
              s3Links ?? []
            );
          setUploadingFiles!([]);
        } else if (!isFileUploading) onSubmit?.({ event: event, message });
      };

      const handleStartNewImageChat = () => {
        if (!isFileUploading && isAnswerComplete && !IGLoading && !uploadURl) {
          setUploadingFiles!([]);
          setFileS3Link!([]);
          startNewChat();
          push(`/${RoutePaths.Chat}/${ChatRoute.New}`);
          updateChatModel?.("image");
          resetMessage();
          setSelectedFile!(null);
        }
      };

      return (
        <>
          <div
            className={classNames(styles.footer, {
              [styles.light]: theme === "light",
              [styles.dark]: theme === "dark",
              [styles.isSidebarOpen]: isOpen && !isShareChat,
              [styles.IChatFooter]:
                ((messages[0]?.images &&
                  messages[0]?.images.length > 0 &&
                  !creditPerQuery) ||
                  creditsPerQuery ||
                  (selectIFile && isFileUploading) ||
                  url ||
                  (message &&
                    (selectedFile === null || selectedFile.length === 0)) ||
                  creditsPerQuery) &&
                gptModel?.type.includes("text"),
              [styles.IGFooter]:
                (gptModel?.type?.includes(ChatType.image) &&
                  !gptModel?.type?.includes(ChatType.image_chat)) ||
                (newMessages[0]?.images && newMessages[0]?.images.length > 0),
              [styles.shareChatFooter]: share,
              [styles.ReceiveShareChatFooter]: isShareChat,
              [styles.shareChat]: theme === "light" && isShareChat,
              [styles.isSideBarClose]: !isOpen,
              [styles.uplodingFile]:
                uploadingFiles && uploadingFiles.length > 0,
            })}
          >
            {share || isShareChat ? (
              <ShareFooter
                selectedMessages={selectedMessages}
                toggleShareChat={toggleShareChat}
                handleSelectAllChats={handleSelectAllChats}
                selectedChatId={selectedChatId}
                loadingShareChat={loadingShareChat}
                setSelectedMessages={setSelectedMessages}
              />
            ) : (
              !isShareChat && (
                <>
                  {showPromptModal && (
                    <PromptLibrary
                      onClose={() => setShowPromptModal(false)}
                      setMessage={setMessage}
                      onTextareaFocus={onTextareaFocus}
                      promptLoading={promptLoading}
                      userPromptLoading={userPromptLoading}
                      textareaRef={textareaRef}
                    />
                  )}
                  {isGenerating && !isAnswerComplete && isContentFound() && (
                    <StopGenerateButton
                      onClick={onStopGeneratingResponse}
                      dataTestId="Stop-generating"
                    />
                  )}

                  {!getMessagesLoading && showScrollToBottom && (
                    <ScrollToBottomButton
                      dataTestId="scroll-to-bottom"
                      onClick={() => scrollToBottom?.({ behavior: "smooth" })}
                    />
                  )}

                  {!getMessagesLoading &&
                    gptModel?.type?.includes(ChatType.image) &&
                    !gptModel?.type?.includes(ChatType.image_chat) && (
                      <div
                        className={classNames(styles.IGOptionsContainer, {
                          [styles.light]: theme === "light",
                          [styles.dark]: theme === "dark",
                          [styles.isSidebarOpen]: isOpen,
                        })}
                      >
                        <div className={styles.IGOptions}>
                          <div className={styles.option}>
                            <div className={styles.label}>
                              <FormattedMessage id="imageGeneration.chatFooter.dropdown.label.imageCount" />
                            </div>
                            <CountDD
                              selectedOption={Number(imageAttributes?.count)}
                              options={ImageCountOptions}
                              maxCount={imageAttributes?.maxCount}
                              onSelect={(option) =>
                                setImageAttribute({
                                  key: "count",
                                  value: option.value,
                                })
                              }
                              dataTestId="chat-footer-count"
                            />
                          </div>

                          <div className={styles.option}>
                            <div className={styles.label}>
                              <FormattedMessage id="imageGeneration.chatFooter.dropdown.label.size" />
                            </div>
                            <SizeDD
                              selectedOption={imageAttributes?.size}
                              options={gptModel?.attributes?.sizes}
                              onSelect={(option) =>
                                setImageAttribute({
                                  key: "size",
                                  value: option,
                                })
                              }
                              dataTestId="chat-footer-size"
                            />
                          </div>
                        </div>

                        <div className={styles.imageCredits}>
                          <FormattedMessage
                            id={
                              Number(
                                imageAttributes?.size?.credits_per_message
                              ) === 1
                                ? "chat.image.one.credit"
                                : "chat.image.credit.perQuery"
                            }
                            values={{
                              credits:
                                Number(imageAttributes?.count) *
                                Number(
                                  imageAttributes?.size?.credits_per_message
                                ),
                            }}
                          />
                        </div>
                      </div>
                    )}

                  {!getMessagesLoading && gptModel?.type.includes("text") && (
                    <CreditDeduction credit={credit} />
                  )}

                  {!getMessagesLoading && (
                    <>
                      <form
                        className={styles.form}
                        onSubmit={(event) => {
                          event.preventDefault();
                          if (
                            fileS3Link &&
                            fileS3Link.length > 0 &&
                            openHistory === false &&
                            !isFileUploading
                          ) {
                            const s3Links: string[] = (uploadingFiles ?? [])
                              .map((file) => file.S3Link)
                              .filter(
                                (link): link is string => link !== undefined
                              );
                            if (
                              uploadingFiles &&
                              uploadingFiles[0]?.fileType === "image"
                            )
                              onSendMessage!(
                                message ? message : "",
                                chatModel,
                                false,
                                s3Links ?? []
                              );
                            else if (
                              uploadingFiles &&
                              uploadingFiles[0]?.fileType === "document"
                            )
                              onSendMessage!(
                                message ? message : "",
                                chatModel,
                                false,
                                [],
                                s3Links ?? []
                              );
                            setUploadingFiles!([]);
                          } else if (!isFileUploading && !url)
                            onSubmit?.({ event: event, message });
                        }}
                        data-testid="chat-footer"
                      >
                        {(gptModel?.type?.includes(ChatType.text) ||
                          gptModel?.type?.includes(ChatType.document)) && (
                          <Tooltip
                            placement="top"
                            theme="light"
                            prompt={true}
                            control={
                              <div
                                data-testid="prompt-library"
                                className={`hover:cursor-pointer text-light dark:text-dark ${styles.chatfooterPromptbtn}`}
                                onClick={onOpenPromptLibrary}
                              >
                                <PromptIcon />
                              </div>
                            }
                          >
                            <FormattedMessage id="prompt.library" />
                          </Tooltip>
                        )}
                        <SearchField
                          value={message}
                          onChange={getMessage}
                          onKeyDown={(event) => {
                            if (!settings?.send_message_with_enter) {
                              if (
                                (event.metaKey || event.ctrlKey) &&
                                event.key === "Enter"
                              ) {
                                if (
                                  fileS3Link &&
                                  fileS3Link.length > 0 &&
                                  openHistory === false &&
                                  !isFileUploading
                                ) {
                                  event.preventDefault();
                                  if (
                                    uploadingFiles &&
                                    uploadingFiles[0]?.fileType === "image"
                                  )
                                    onSendMessage!(
                                      message ? message : "",
                                      chatModel,
                                      false,
                                      fileS3Link ?? []
                                    );
                                  else if (
                                    uploadingFiles &&
                                    uploadingFiles[0]?.fileType === "document"
                                  )
                                    onSendMessage!(
                                      message ? message : "",
                                      chatModel,
                                      false,
                                      [],
                                      fileS3Link ?? []
                                    );
                                  setUploadingFiles!([]);
                                } else if (!isFileUploading && !url)
                                  onEnter?.({ event: event, message });
                              }
                            } else {
                              if (!event.shiftKey && event.key === "Enter") {
                                if (
                                  fileS3Link &&
                                  fileS3Link.length > 0 &&
                                  openHistory === false &&
                                  !isFileUploading
                                ) {
                                  event.preventDefault();
                                  if (
                                    uploadingFiles &&
                                    uploadingFiles[0]?.fileType === "image"
                                  )
                                    onSendMessage!(
                                      message ? message : "",
                                      chatModel,
                                      false,
                                      fileS3Link ?? []
                                    );
                                  else if (
                                    uploadingFiles &&
                                    uploadingFiles[0]?.fileType === "document"
                                  )
                                    onSendMessage!(
                                      message ? message : "",
                                      chatModel,
                                      false,
                                      [],
                                      fileS3Link ?? []
                                    );
                                  setUploadingFiles!([]);
                                } else if (!isFileUploading && !url)
                                  onEnter?.({ event: event, message });
                              }
                            }
                          }}
                          onFocus={() => setIsTextareaFocused(true)}
                          onBlur={() => setIsTextareaFocused(false)}
                          autoFocus
                          isloading={!isAnswerComplete}
                          textareaRef={textareaRef}
                          isFieldFocused={isTextareaFocused}
                          onTextareaFocus={onTextareaFocus}
                          isFileUploading={isFileUploading}
                          handleSubmit={handleSubmit}
                          updateChatModel={updateChatModel}
                          selectedFile={selectedFile}
                          setSelectedFile={setSelectedFile}
                          chatHistoryPagination={chatHistoryPagination}
                          setChatHistory={setChatHistory}
                          setIsMainScreenOpen={setIsMainScreenOpen}
                          setUploadUrl={setUploadUrl}
                          resetMessage={resetMessage}
                          message={message}
                          url={url}
                          setURL={setURL}
                          onSendMessage={onSendMessage}
                          setOpenHistory={setOpenHistory}
                          uploadingFiles={uploadingFiles}
                          setUploadingFiles={setUploadingFiles}
                          setIsFileUploading={setIsFileUploading}
                          setFileS3Link={setFileS3Link}
                          isAllowUploadFile={isAllowUploadFile}
                          chatItem={chatItem}
                          setErrorModal={setErrorModal}
                        />
                        <Tooltip
                          placement="top"
                          theme={theme}
                          prompt={true}
                          control={
                            <div
                              className={classNames(styles.chatfooterbtn, {
                                [styles.imagegeneration]:
                                  gptModel?.type?.includes(ChatType.image) &&
                                  !gptModel?.type?.includes(
                                    ChatType.image_chat
                                  ),
                              })}
                              onClick={handleStartNewImageChat}
                            >
                              <div
                                className={`flex justify-center items-center h-[50px] ${styles.imagegenerationIcon}`}
                              >
                                <ImageGeneration
                                  imageGeneration={
                                    gptModel?.type?.includes(ChatType.image) &&
                                    !gptModel?.type?.includes(
                                      ChatType.image_chat
                                    )
                                  }
                                  disable={!isFileUploading && isAnswerComplete}
                                />
                              </div>
                            </div>
                          }
                        >
                          <FormattedMessage id="doc.generate.image" />
                        </Tooltip>
                      </form>
                    </>
                  )}
                </>
              )
            )}
          </div>
          <ErrorModalSection
            errorModal={errorModal}
            setErrorModal={setErrorModal}
            setIsMainScreenOpen={setIsMainScreenOpen}
          />
        </>
      );
    }
  )
);
