export interface IOption {
  label: any;
  value: any;
}

export const ImageCountOptions: IOption[] = [
  {
    label: 1,
    value: 1,
  },
  {
    label: 2,
    value: 2,
  },
  {
    label: 3,
    value: 3,
  },
  {
    label: 4,
    value: 4,
  },
  {
    label: 5,
    value: 5,
  },
];

export const supportedModels = [
  "Claude-3-Haiku",
  "Claude-3-Opus",
  "Claude 3.5 Sonnet",
  "GPT-4-TURBO",
  "GPT-4-Omni"
];
