import React from "react";
import classNames from "classnames";
import styles from "./answerContainer.module.scss";
import { DocIcon } from "pages/ChatPage/components/icons/DocIcon";
import { useSelector } from "redux/hooks";
import { IFile } from "redux/actions";
import { Tooltip } from "components";

interface IFileLinkProps {
    file: IFile[];
    contentFile?: boolean;
}

const FileLink: React.FC<IFileLinkProps> = ({ file, contentFile }) => {
    const { theme } = useSelector((state) => state.authReducer);

    return (
        <div
            className={classNames(styles.fileContainer, {
                [styles.docPadding]: contentFile,
            })}
        >
            <div
                className={classNames(styles.fileText, {
                    [styles.light]: theme === "light",
                    [styles.dark]: theme === "dark",
                })}
                style={{ display: 'flex', gap: '20px', overflowX: 'auto' }}
            >
                {file && file.map((file) => {
                    const extractFileName = (path: string) => {
                        const decodedPath = decodeURIComponent(path);
                        const parts = decodedPath.split('/');
                        const lastSegment = parts[parts.length - 1];
                        const firstHyphenIndex = lastSegment.indexOf('-');
                        const secondHyphenIndex = lastSegment.indexOf('-', firstHyphenIndex + 1);
                        return secondHyphenIndex !== -1 ? lastSegment.substring(secondHyphenIndex + 1) : lastSegment;
                    };

                    const fileName = extractFileName(file?.path as string);

                    return (
                        <Tooltip
                            key={fileName}
                            control={
                                <a href={file?.path as string} target="_blank"
                                    rel="noreferrer"
                                    className="mt-3 w-[20px]">
                                    <div className={classNames(styles.fileDisplay, {
                                        [styles.light]: theme === "light",
                                        [styles.dark]: theme === "dark",
                                    })}>
                                        <div className={styles.fileIcon}>
                                            <DocIcon />
                                        </div>
                                        <div className={styles.fileInfo}>
                                            <div className={styles.fileName}>{fileName}</div>
                                            <div className={styles.fileType}>Documents</div>
                                        </div>
                                    </div>
                                </a>
                            }
                            placement="top"
                            theme="light"
                        >
                            {fileName}

                        </Tooltip>
                    );
                })}
            </div>
        </div>
    );
};

export default FileLink;