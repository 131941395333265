import { TYPES } from "../../types";
import { api } from "../../api";
import { store } from "../../store";
import { IChatModel, setChatModel } from "../chatModels";
import { SetStateAction } from "react";
import { updateCreditLimit, updateCredits } from "../planSubscription";

export interface ISendMessageBody {
  message: string;
  newChatBoolean: boolean;
  chatId: number | null;
  model: string;
  chatType?: EChatType;
  messageViewType?: EMessageViewType;
  file_path?: string[];
  regenerate?: boolean;
  images?: string[];
}

export interface IGenerateRelatedQuestionsBody {
  chatId: number | null;
  messageViewType?: EMessageViewType;
}

export interface IRemoveGeneratedQuestions {
  messageId: number;
  messageViewType?: EMessageViewType;
}

export interface IGetAllChat {
  chats: IChat[];
}

export interface IGetChatHistory {
  messages: IMessage[];
}

export type EMessageViewType =
  (typeof MessageViewType)[keyof typeof MessageViewType];

export const MessageViewType = {
  new: "new",
  history: "history",
} as const;

export type EChatType = (typeof ChatType)[keyof typeof ChatType];

export const ChatType = {
  text: "text",
  document: "document",
  image: "image",
  image_chat: "image_chat",
} as const;

export type EMessageType = (typeof MessageType)[keyof typeof MessageType];

export const MessageType = {
  user: "user",
  assistant: "assistant",
} as const;

export interface IFile {
  id: number | null;
  model_type?: string;
  model_id: number | null;
  name: string;
  path: string | null;
  created_at: string | null;
  updated_at: string | null;
}

export interface IUpscaledImage {
  id?: number;
  image_id: number;
  upscale_image_id: number;
  created_at?: string;
  updated_at?: string;
}

export interface IImage {
  id: number;
  model_type: string;
  model_id: number;
  name: string | null;
  path: string;
  size: string;
  created_at: string;
  updated_at: string;
  upscaled_images?: IUpscaledImage[];
}

export interface ISharImgeChat {
  path?: string;
}

export interface IChat {
  id: number;
  chat_type: EChatType;
  topic: string;
  is_topic_updated?: number | null;
  document_index_name?: string;
  user_id: number;
  created_at?: string;
  updated_at?: string;
  files?: IFile[];
  last_assistant_message?: IMessage;
  searched_messages?: IMessage[];
  model?: IChatModel;
}

export interface IMessage {
  id?: number;
  content?: string;
  images: IImage[];
  type?: EMessageType;
  chat_id?: number;
  created_at?: string;
  updated_at?: string;
  isNew?: boolean;
  related_questions?: string[];
  files?: IFile[];
  regenerate_model_id?: number | null;
}
// interface ImageContainer {
//   images: ISharImgeChat[];
// }

export interface IShareChatMessage {
  content?: string;
  type?: EMessageType;
  images: ISharImgeChat[];
  files?: IFile[];
  // 0: ImageContainer;
}

export interface IStopGeneratingMessageBody {
  chatId?: number;
  messageId?: number;
}

export interface ISaveFilePathBody {
  path: string;
  name: string;
}

export interface IChatHistoryResponse {
  chat: IChat;
  messages: IMessage[];
}

export interface IPrompt {
  id: number;
  title: string;
  prompt: string;
  created_at: string;
  updated_at: string;
}

export interface ISavePrompt {
  title: string;
  prompt: string;
}

export interface IGetChat {
  token: string;
}

export interface IShareChat {
  id: number;
  chat_id: number;
  model_id: number;
  chat_type: string;
  token: string;
  messages: IShareChatMessage[];
  created_at: string;
  updated_at: string;
}

export interface IChatSetting {
  real_time_results: boolean;
  related_questions: boolean;
  send_message_with_enter: boolean;
}

export const startNewChat = () => {
  store.dispatch({ type: TYPES.START_NEW_CHAT, payload: [] });
};

export const askNewChatQuestion = (message: IMessage) => {
  store.dispatch({ type: TYPES.NEW_CHAT, payload: message });
};

export const answerNewChatQuestion = (message: IMessage) => {
  store.dispatch({ type: TYPES.ANSWER_NEW_CHAT, payload: message });
};

export const askRegularQuestion = (message: IMessage) => {
  store.dispatch({ type: TYPES.REGULAR_CHAT, payload: message });
};

export const updateNewRegularQuestion = (message: IMessage) => {
  store.dispatch({ type: TYPES.UPDATED_NEW_REGULAR_CHAT, payload: message });
};

export const updatedRegularQuestion = (message: IMessage) => {
  store.dispatch({ type: TYPES.UPDATE_REGULAR_CHAT, payload: message });
};

export const answerRegularQuestion = (message: IMessage) => {
  store.dispatch({ type: TYPES.ANSWER_REGULAR_CHAT, payload: message });
};

export const getAllChats = ({
  search,
  page,
  perPage,
}: {
  search?: string;
  page?: number;
  perPage?: number;
}) => {
  return new Promise((resolve, reject) => {
    const url =
      page && perPage
        ? `/api/chat?search=${search}&page=${page}&perPage=${perPage}`
        : `/api/chat?search=${search}`;
    api
      .get(url)
      .then((res: any) => {
        resolve(res.data.chats);
        store.dispatch({ type: TYPES.GET_ALL_CHATS, payload: res.data.chats });
      })
      .catch((err: any) => {
        reject(err);
        store.dispatch({ type: TYPES.GET_ALL_CHATS, payload: [] });
      });
  });
};

export const addNewChatHistory = (chat: IChat) => {
  store.dispatch({ type: TYPES.ADD_CHAT_HISTORY, payload: chat });
};

export const updateChatHistory = (id: number, topic: string) => {
  return new Promise((resolve, reject) => {
    api
      .put(`/api/chat/${id}`, { topic: topic })
      .then((res) => {
        resolve(res.data);
        store.dispatch({
          type: TYPES.UPDATE_CHAT_HISTORY,
          payload: { id, topic },
        });
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const deleteChatHistory = (id: number) => {
  return new Promise((resolve, reject) => {
    api
      .delete(`/api/chat/${id}`)
      .then((res) => {
        resolve(res.data);
        store.dispatch({
          type: TYPES.DELETE_CHAT_HISTORY,
          payload: { id: id },
        });
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getChatHistory = (chatId: number) => {
  return new Promise((resolve, reject) => {
    api
      .get(`/api/chat/history/${chatId}`)
      .then((res: any) => {
        const response: IChatHistoryResponse = res.data;
        const { chat, messages } = response;

        let messagesList = messages;
        if (chat.files) {

          const newMessage = {
            id: messagesList[0].id,
            type: MessageType.user,
            images: [],
            file: chat.files,
          };
          if (messagesList[0]?.type === 'assistant') {
            messagesList.unshift(newMessage);
          }

          messagesList = messagesList.map((message: any, index) => {
            if (message?.type === 'user' && index === 0) {
              return {
                ...message,
                file: chat.files,
                content: message.content,
              };
            } else {
              return message;
            }
          });
        }

        resolve(chat);
        store.dispatch({
          type: TYPES.GET_CHAT_HISTORY,
          payload: messagesList,
        });
        setTimeout(() => {
          if (chat?.model) {
            let Model = chat.model;
            if (Model && typeof Model.type === "string") {
              Model.type = JSON.parse(Model.type);
            }
            if (Model && typeof Model.attributes === "string") {
              Model.attributes = JSON.parse(Model.attributes);
            }
            setChatModel(Model);
          }
        }, 300);
        resolve(chat);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

export const stopGeneratingResponse = (data: IStopGeneratingMessageBody) => {
  return new Promise((resolve, reject) => {
    api
      .post("/api/chat/message/stop", data)
      .then((res: any) => {
        resolve(res.data);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

export const getNewChatTopic = (chatId: number) => {
  return new Promise((resolve, reject) => {
    api
      .get(`/api/chat/${chatId}`)
      .then((res: any) => {
        addNewChatHistory(res.data);
        resolve(res.data);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

export const generatePresignedURL = (data: {
  name: string;
  type?: null | typeof ChatType.document | typeof ChatType.image_chat;
  signal?: AbortSignal;
}) => {
  return new Promise((resolve, reject) => {
    api
      .post("/api/generate-s3-url", { name: data.name, type: data?.type }, { signal: data.signal })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const saveFilePath = ({
  path,
  name,
  token,
  message,
  setISUploadURL,
  uploadUrl,
  resetMessage,
  signal,
  model,
  setIsFileUploading,
}: {
  path: string;
  name: string;
  token: string | undefined;
  message?: string;
  setISUploadURL?: React.Dispatch<SetStateAction<boolean>>;
  uploadUrl?: boolean;
  resetMessage?: () => void;
  signal?: AbortSignal;
  model: string;
  setIsFileUploading?: React.Dispatch<SetStateAction<boolean>>;
}) => {
  return new Promise((resolve, reject) => {
    fetch(`${process.env.REACT_APP_API_BASEURL}/api/chat/file`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      signal: signal,
      body: JSON.stringify({ path, name, message, model }),
    })
      .then(async (response: any) => {
        if (!response.ok) {
          const errorResponse = await response.json();
          const errorMessage = errorResponse.message || `Something went wrong`;
          return reject(new Error(errorMessage));
        }
        if (!uploadUrl) {
          setIsFileUploading!(false);
        }
        if (uploadUrl) {
          setISUploadURL!(false);
          resetMessage!();
        }

        const reader = response?.body?.getReader();
        const decoder = new TextDecoder();
        let i = 0;
        let content = "";
        let texts = "";
        let isJsonParsed = false;
        let jsonData = null;
        while (true) {
          const streamResult = await reader?.read();
          const decoderText = decoder.decode(streamResult?.value);
          content += decoder.decode(streamResult?.value || new Uint8Array(), {
            stream: !streamResult?.done,
          });
          let completeMessage = decoderText;
          while (!isJsonParsed) {
            let leftBracket = content.indexOf("{");
            let rightBracket = content.lastIndexOf("}");
            if (leftBracket === -1 || rightBracket === -1) {
              break;
            }
            if (leftBracket !== -1 && rightBracket !== -1) {
              const jsonDataString = content.substring(
                leftBracket,
                rightBracket + 1
              );
              const jsonData = JSON.parse(jsonDataString);
              if (jsonData.chat) {
                texts = content.substring(rightBracket + 1);
                isJsonParsed = true;
              }
            }
            completeMessage = content.substring(leftBracket, rightBracket + 1);
            content = content.substring(rightBracket + 1);
          }
          if (streamResult?.done) {
            resolve({ jsonData, content });
            break;
          }
          if (i === 0) {
            try {
              jsonData = JSON.parse(
                completeMessage && texts !== "" ? completeMessage : decoderText
              );
              completeMessage = "";

              if (jsonData) {
                updateCredits(jsonData.credits);
                updateCreditLimit({
                  daily_limit: jsonData.daily_limit,
                  used_today: jsonData.used_today,
                });
                store.dispatch({
                  type: TYPES.SAVE_FILE_PATH,
                  payload: {
                    chatItem: {
                      id: jsonData?.chat?.id,
                      chat_type: jsonData?.chat?.chat_type,
                      topic: jsonData?.chat?.topic,
                      is_topic_updated: jsonData?.chat?.is_topic_updated,
                      document_index_name: jsonData?.chat?.document_index_name,
                      user_id: jsonData?.chat?.user_id,
                      created_at: jsonData?.chat?.created_at,
                      updated_at: jsonData?.chat?.updated_at,
                      file: jsonData.file,

                      last_assistant_message: {
                        id: jsonData?.chat?.id,
                        content: content,
                        type: "assistant",
                        created_at: jsonData?.chat?.created_at,
                        updated_at: jsonData?.chat?.updated_at,
                        chat_id: jsonData?.chat?.id,
                      },
                    },
                    message: [
                      ...(message ? [{
                        id: jsonData?.message?.id,
                        content: message,
                        type: "user",
                        images: [],
                        file: jsonData.file,
                      }] : [{
                        id: jsonData?.assistant_message?.id,
                        type: "user",
                        images: [],
                        file: jsonData.file,
                      }]),
                      {
                        id: jsonData?.assistant_message?.id,
                        content: content,
                        type: "assistant",
                        created_at: jsonData?.chat?.created_at,
                        updated_at: jsonData?.chat?.updated_at,
                        chat_id: jsonData?.chat?.id,
                        file: jsonData.file,
                      },
                    ],
                  },
                });
              }
            } catch (error: any) {
              console.error("Error parsing JSON:", error);
            }
          } else {
            if (jsonData) {
              store.dispatch({
                type: TYPES.SAVE_FILE_PATH,
                payload: {
                  chatItem: {
                    id: jsonData?.chat?.id,
                    chat_type: jsonData?.chat?.chat_type,
                    topic: jsonData?.chat?.topic,
                    is_topic_updated: jsonData?.chat?.is_topic_updated,
                    document_index_name: jsonData?.chat?.document_index_name,
                    user_id: jsonData?.chat?.user_id,
                    created_at: jsonData?.chat?.created_at,
                    updated_at: jsonData?.chat?.updated_at,
                    file: jsonData.file,

                    last_assistant_message: {
                      id: jsonData?.chat?.id,
                      content: content,
                      type: "assistant",
                      created_at: jsonData?.chat?.created_at,
                      updated_at: jsonData?.chat?.updated_at,
                      chat_id: jsonData?.chat?.id,
                    },
                  },
                  message: [
                    ...(message ? [{
                      id: jsonData?.message?.id,
                      content: message,
                      type: "user",
                      images: [],
                      file: jsonData.file,
                    }] : [{
                      id: jsonData?.assistant_message?.id,
                      type: "user",
                      images: [],
                      file: jsonData.file,
                    }]),
                    {
                      id: jsonData?.assistant_message?.id,
                      content: content,
                      type: "assistant",
                      created_at: jsonData?.chat?.created_at,
                      updated_at: jsonData?.chat?.updated_at,
                      chat_id: jsonData?.chat?.id,
                      file: jsonData.file,
                    },
                  ],
                },
              });
            }
          }
          i++;
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const removeRelatedQuestions = ({
  messageId,
  messageViewType,
}: IRemoveGeneratedQuestions) => {
  if (messageViewType === "new") {
    store.dispatch({
      type: TYPES.REMOVE_RELATED_QUESTIONS_FROM_NEW_MESSAGES,
      payload: messageId,
    });
  }
  if (messageViewType === "history") {
    store.dispatch({
      type: TYPES.REMOVE_RELATED_QUESTIONS_FROM_MESSAGES,
      payload: messageId,
    });
  }
};

export const generateRelatedQuestions = ({
  chatId,
  messageViewType,
  signal,
}: IGenerateRelatedQuestionsBody  & { signal?: AbortSignal }) => {
  return new Promise((resolve, reject) => {
    api
      .post("/api/chat/related-questions", { chat_id: chatId },{signal})
      .then((res) => {
        resolve(res.data);
        if (messageViewType === "new") {
          store.dispatch({
            type: TYPES.GET_GENERATED_QUESTIONS_NEW,
            payload: res.data,
          });
        }
        if (messageViewType === "history") {
          store.dispatch({
            type: TYPES.GET_GENERATED_QUESTIONS_HISTORY,
            payload: res.data,
          });
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const removeLastMessage = (messageViewType: EMessageViewType) => {
  if (messageViewType === "new") {
    store.dispatch({
      type: TYPES.REMOVE_LAST_MESSAGE_FROM_NEW_MESSAGES,
    });
  }
  if (messageViewType === "history") {
    store.dispatch({
      type: TYPES.REMOVE_LAST_MESSAGE_FROM_MESSAGES,
    });
  }
};

export const getAllPropts = () => {
  return new Promise((resolve, reject) => {
    api
      .get(`/api/prompts`)
      .then((res) => {
        store.dispatch({
          type: TYPES.GET_ALL_PROMPT,
          payload: res.data,
        });
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const SavePrompts = (data: ISavePrompt) => {
  return new Promise((resolve, reject) => {
    api
      .post("/api/prompts/user", data)
      .then((res) => {
        getSavePrompts();
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getSavePrompts = () => {
  return new Promise((resolve, reject) => {
    api
      .get(`/api/prompts/user`)
      .then((res) => {
        store.dispatch({
          type: TYPES.GET_USER_PROMPT,
          payload: res.data,
        });
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const deleteUserPRompts = (id: number) => {
  return new Promise((resolve, reject) => {
    api
      .delete(`/api/prompts/user/${id}`)
      .then((res) => {
        resolve(res.data);
        store.dispatch({
          type: TYPES.DELETE_USER_PROMPT,
          payload: { id: id },
        });
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const updateUserPrompt = (id: number, data: ISavePrompt) => {
  return new Promise((resolve, reject) => {
    api
      .put(`/api/prompts/user/${id}`, data)
      .then((res) => {
        resolve(res.data);
        store.dispatch({
          type: TYPES.UPDATE_USER_PROMPT,
          payload: { id, data },
        });
      })
      .catch((err) => {
        reject(err);
      });
  });
};


export const ShareChat = ({ chat_id, messages }: { chat_id?: number, messages?: [] }) => {
  return new Promise((resolve, reject) => {
    api
      .post("/api/chat/share", { chat_id, messages })
      .then((res: any) => {
        const token = res.data?.token;
        const baseURL = window.location.origin;
        resolve(res.data);
        try {
          const path = `${baseURL}/share-chat/${token}`;
          const textArea = document.createElement("textarea");
          textArea.value = path;
          textArea.style.position = "fixed";
          document.body.appendChild(textArea);
          textArea.focus();
          textArea.select();

          const successful = document.execCommand("copy");
          document.body.removeChild(textArea);

          if (successful) {
            resolve(path);
          } else {
            reject("Failed to copy path to clipboard");
          }
        } catch (err) {
          console.error("Error copying path to clipboard:", err);
          reject(err);
        }
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

export const getShareChat = (data: IGetChat) => {
  return new Promise((resolve, reject) => {
    api
      .get(`/api/shared-chat/${data?.token} `)
      .then((res: any) => {
        resolve(res.data);
        store.dispatch({
          type: TYPES.GET_SHARE_CHAT,
          payload: res.data,
        });
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

export const CopyShareChat = (data: IGetChat) => {
  return new Promise((resolve, reject) => {
    api
      .post("/api/shared-chat/copy", data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getChatSettings = () => {
  return new Promise((resolve, reject) => {
    api
      .get("/api/user-setting")
      .then((res) => {
        resolve(res?.data);
        store.dispatch({ type: TYPES.GET_CHAT_SETTING, payload: res?.data });
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const SetChatSetting = (data: IChatSetting) => {
  return new Promise((resolve, reject) => {
    api
      .post("/api/user-setting", data)
      .then((res) => {
        getChatSettings().catch()
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const DeleteS3Link = (s3Link: string) => {
  return new Promise((resolve, reject) => {
    api
      .delete("api/s3-file", {
        data:{ file_path: s3Link}
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      })
  })
}
export const removeMultipleChat =(chat_ids: number[] ) =>{
  return new Promise((resolve, reject) => {
    api
      .delete(`/api/chat/delete-multiple`, {
        data: { chat_ids }
      })
      .then((res) => {
        resolve(res.data);
        store.dispatch({
          type: TYPES.DELETE_MULTIPLE_CHAT_HISTORY,
          payload: {chat_ids },
        });
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export const deleteAllConversation=() =>{
  return new Promise((resolve, reject) => {
    api
      .delete(`/api/chat/clear-all`)
      .then((res) => {
        resolve(res.data);
        getAllChats({ search: '' });
      })
      .catch((err) => {
        reject(err);
      });
  });
}